<template>
    <div class="alert-timeline">
        <div class="alert-timeline__timeline-section">
            <router-link class="alert-timeline__back-option" to="/associates_alert">
                <img class="alert-timeline__back-option-icon" src="@/assets/icons/ic_twotone-arrow-back-ios-new.svg" />
                Alert List
            </router-link>

            <AssociateInfo :alert-id="alertId" />

            <div class="alert-timeline__events">
                <AssociatesTracking :alert-id="alertId" @new-coordinates="setNewCoordinates" />
            </div>
        </div>

        <!-- Google Maps -->
        <GoogleMaps :location="newCoordinates" />
    </div>
</template>

<script setup>
import { ref } from 'vue'
import AssociatesTracking from '../Components/AssociatesTracking.vue'
import AssociateInfo from '../Components/AssociateInfo.vue'
import GoogleMaps from '../../../common/GoogleMaps.vue'


const props = defineProps(['alertId'])
const newCoordinates = ref(null)

function setNewCoordinates(coordinates) {
    console.log(coordinates)
    newCoordinates.value = coordinates
}
</script>