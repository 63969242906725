<template>
    <div class="top-nav">
        <div class="top-nav__content">
            <img src="@/assets/images/user-profile.svg" class="top-nav__client-image" width="30" height="30" alt=""
                v-if="!clientStore.client.image">

            <img :src="filesPath + clientStore.client.image" class="top-nav__client-image" width="30" height="30"
                v-else />

            <div class="top-nav__client-info popup-options">
                <div class="top-nav__client-name">
                    {{ clientStore.client.name }}
                </div>
                <div class="top-nav__hotel-name">
                    {{ clientStore.client.hotel }}
                </div>

                <div class="popup-options__options-content">
                    <ul class="popup-options__options-list">
                        <li>
                            <router-link to="/customer">Config Account</router-link>
                        </li>
                        <li>
                            <a :href="billingPortalPath">Billing Portal</a>
                        </li>
                    </ul>
                </div>
            </div>

        </div>

        <div class="top-nav__content">
            <PanicAlert />

            <a href="#" class="top-nav__option" @click.prevent="openCrispChat">
                <img src="@/assets/icons/support.svg" />
                Support
            </a>
            <!-- <div class="top-nav__option">
                <img src="@/assets/icons/bell-notification.svg" />
                Notifications
            </div> -->
        </div>
    </div>
</template>

<script setup>
import { useClientStore } from '../../../stores/ClientStore.js'
import { ref, onBeforeMount } from 'vue'
import { mainServices } from '../Services/MainServices.js'
import PanicAlert from './PanicAlert.vue'

const clientStore = useClientStore()
const { getBilligPortalnUrl } = mainServices()
const filesPath = process.env.VUE_APP_FILES_PATH
const billingPortalPath = ref(null)

onBeforeMount(async () => {
    const response = await getBilligPortalnUrl()

    if (response.hasErrors) return console.error(`Error getting billing portal url: ${response.hasErrors}`)

    billingPortalPath.value = response.data

    // * Hide Crisp Chat
    hideCrispChat()
})

function hideCrispChat() {
    $crisp.push(["do", "chat:hide"])

    $crisp.push(["on", "chat:closed", (e) => {
        $crisp.push(["do", "chat:hide"])
    }])
}

function openCrispChat() {
    $crisp.push(["do", "chat:show"])
    $crisp.push(["do", "chat:open"])
}
</script>
