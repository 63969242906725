import { defineStore } from "pinia";
import { ref, onMounted, watch } from 'vue'
import { httpRequests } from '../helpers/HttpRequests'
import { sweetAlerts } from '@/helpers/SweetAlert.js'

export const useCallAlertStore = defineStore('callAlertStore', () => {
    const { getRequest, postRequest } = httpRequests()
    const associate = ref({ info: {} })
    const soundNotification = ref('')
    const notchStatus = ref({ show: false })
    const { sweetSuccess } = sweetAlerts()

    onMounted(async () => { setCallRequest() })

    watch(associate.value, (associate) => { soundCall(associate) })

    async function getCallsRequests() {
        const response = await getRequest('/web/rapid_sos/get_requests')

        if (response.hasErrors) return console.error(`Error getting the call requests: ${response.message}`)

        return response.data
    }

    async function setCallRequest() {
        associate.value.info = await getCallsRequests()
    }

    async function acceptCallRequestService() {
        const callRequestId = { callRequestId: associate.value.info.alertCallId }
        const response = await postRequest('/web/rapid_sos/accept_request', callRequestId)

        if (response.hasErrors) return console.error(`Error accepting call requests: ${response.message}`)

        sweetSuccess("Call 911 Request Accepted.")

        return setCallRequest()
    }

    async function declineCallRequestService() {
        const callRequestId = { callRequestId: associate.value.info.alertCallId }
        const response = await postRequest('/web/rapid_sos/decline_request', callRequestId)

        if (response.hasErrors) return console.error(`Error declining call requests: ${response.message}`)

        sweetSuccess("Call 911 Request Declined.")

        return setCallRequest()
    }

    function soundCall(associate) {
        if (!soundNotification.value) soundNotification.value = new Audio(require('@/assets/sounds/ring-phone-190265.mp3'))

        if (Object.keys(associate.info).length) {
            soundNotification.value.loop = true
            soundNotification.value.play()

            notchStatus.value.show = true
        } else {
            soundNotification.value.pause()
            soundNotification.value = ''

            notchStatus.value.show = false
        }
    }

    return { associate, notchStatus, getCallsRequests, setCallRequest, acceptCallRequestService, declineCallRequestService }
})