import { useClientStore } from '@/stores/ClientStore.js'

export function flicEvent() {
    const { client } = useClientStore()

    // * Subscribe to panic alert event
    function joinFlicEvent() {
        console.log('Join in panic alert channel ✅')

        return Echo.private(`flic-button.${client.id}`)
    }

    // * Leave to panic alert event
    function leaveFlicEvent() {
        Echo.leave(`flic-button.${client.id}`)
    }

    return { joinFlicEvent, leaveFlicEvent }
}