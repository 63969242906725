<template>
  <div class="top-nav__option top-nav--alert-option" @click="toggleRecording">
    <img src="@/assets/icons/vuesax/linear/alarm.svg" />
    <span v-if="!recording">Help</span>
    <span class="top-nav__recording-icon" v-else>
      <img src="@/assets/icons/vuesax/linear/dot-red.svg" alt="" />
      Recording
    </span>
  </div>
</template>

<script setup>
import { ref, onUnmounted, onMounted } from "vue"
import { mainServices } from "../Services/MainServices.js"
import { flicEvent } from "../../../sockets-events/FlicEvent.js"
import { usePanicAlertStore } from '@/stores/PanicAlertStore.js'

const { createAlert, disableAlert, uploadVideoEvidences } = mainServices()
const { joinFlicEvent, leaveFlicEvent } = flicEvent()

const recording = ref(false);
const alertId = ref(null);
const mediaRecorder = ref(null);
const recordedChunks = ref([]);
const { alert } = usePanicAlertStore()
let currentStream = null;

const cordsRequest = ref({
  lat: "3.3949282",
  lng: "-76.5572862"
})

onMounted(async () => {
  joinFlicEvents()

  // TODO: Enables this line after present of RapidSOS Production
  // if (alert.panicAlertStatus) await initCamera()
})

onUnmounted(() => {
  stopRecording()
  stopStream()
})

async function initCamera() {
  if (mediaRecorder.value) return;

  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });

    if (!stream) {
      throw new Error('Failed to get camera stream');
    }

    currentStream = stream;

    // Try MP4 with different codecs
    const mimeTypes = [
      'video/mp4;codecs=avc1.42E01E,mp4a.40.2',
      'video/mp4',
      'video/x-matroska;codecs=avc1,opus'
    ];

    let selectedMimeType;

    for (const type of mimeTypes) {
      if (MediaRecorder.isTypeSupported(type)) {
        selectedMimeType = type;
        break;
      }
    }

    if (!selectedMimeType) {
      throw new Error('No supported MIME type found for MP4 recording');
    }

    try {
      mediaRecorder.value = new MediaRecorder(stream, {
        mimeType: selectedMimeType,
        videoBitsPerSecond: 2500000
      });
    } catch (error) {
      console.error('Failed to initialize MediaRecorder:', error);
      stopStream();
      throw new Error('Failed to initialize recording device');
    }

    mediaRecorder.value.ondataavailable = (event) => {
      try {
        if (event.data.size > 0) {
          recordedChunks.value.push(event.data);
        }
      } catch (error) {
        console.error('Error handling recorded data:', error);
        stopRecording();
      }
    };

    mediaRecorder.value.onerror = (error) => {
      console.error('MediaRecorder error:', error);
      stopRecording();
      stopStream();
    };

    mediaRecorder.value.onstop = async () => {
      try {
        if (recordedChunks.value.length === 0) {
          throw new Error('No recorded data available');
        }

        const blob = new Blob(recordedChunks.value, { type: 'video/mp4' });

        // Create a proper MP4 file
        const videoFile = new File([blob], 'recording.mp4', {
          type: 'video/mp4',
          lastModified: Date.now()
        });

        const formData = new FormData();

        formData.append("video_file", videoFile);
        formData.append("alert_id", alert.id);

        await uploadVideoEvidences(formData);
        await disableAlert(alert.id);

        // alert.panicAlertStatus = false
      } catch (error) {
        console.error("Recording processing error:", error);
      } finally {
        recordedChunks.value = [];
        stopStream();
      }
    };

    startRecording();
  } catch (error) {
    console.error("Camera initialization failed:", error.message);
    recording.value = false;
    stopStream();
    throw new Error('Failed to initialize camera: ' + error.message);
  }
}

function startRecording() {
  try {
    if (!mediaRecorder.value) {
      throw new Error('MediaRecorder not initialized');
    }

    if (mediaRecorder.value.state === 'recording') {
      return;
    }

    recordedChunks.value = [];
    mediaRecorder.value.start(1000);
    recording.value = true;
  } catch (error) {
    console.error('Failed to start recording:', error);
    recording.value = false;
    stopStream();
  }
}

function stopRecording() {
  try {
    if (!mediaRecorder.value || mediaRecorder.value.state !== 'recording') return

    mediaRecorder.value.stop()
    recording.value = false

    // * Set alert id value to NULL
    // alert.id = null
  } catch (error) {
    console.error('Failed to stop recording:', error)
    recording.value = false

    // * Set alert id and alert status values
    // alert.id = null
    // alert.panicAlertStatus = false

    stopStream()
  }
}

function stopStream() {
  try {
    if (currentStream) {
      currentStream.getTracks().forEach(track => {
        try {
          track.stop();
        } catch (error) {
          console.error('Error stopping track:', error);
        }
      });
      currentStream = null;
    }
    mediaRecorder.value = null;
  } catch (error) {
    console.error('Error stopping stream:', error);
  }
}

async function toggleRecording() {
  if (alert.panicAlertStatus) return stopRecording()

  const response = await createAlert(cordsRequest.value)

  if (response.hasErrors) return console.error(`Error creating alert: ${response.message}`)

  // * Set alert id and alert status values
  // alert.id = null
  // alert.id = response.data.data.alert_id
  // alert.panicAlertStatus = true

  await initCamera();
}

async function joinFlicEvents() {
  joinFlicEvent('.flic-events')
    .listen(".flic-events", (event) => { 
      console.log('entro al  flic event')
      toggleRecording() 
    })
}


</script>
