<template>
    <div class="alert-timeline__map" id="map"></div>
</template>

<script setup>
import { watch } from 'vue'
import { Loader } from '@googlemaps/js-api-loader'

const props = defineProps(['location'])
let map = null
let marker = null
let cords = null

watch(() => props.location, (cordsObj) => {
    if (!cords) {
        cords = cordsObj
        return initMap(cords)
    }

    updateMarker(cordsObj)
})

// * Init Google Maps
async function initMap(cords) {
    // * Init Loader Google Maps
    const loader = new Loader({
        apiKey: "AIzaSyAEvVxGivtf7OjVFxgi1i0bQczzXM6cYRE",
        version: "weekly",
    })

    // * Load Google Maps libraries
    const { Map } = await loader.importLibrary('maps')
    const { AdvancedMarkerElement } = await loader.importLibrary('marker')

    // * Set Google Maps element
    const element = document.getElementById("map")

    // * Set Google Maps options
    let mapOptions = {
        zoom: 19,
        center: cords,
        heading: 320,
        tilt: 47.5,
        mapId: 'a060330a0cda0839'
    }

    // * Load and set map options
    map = new Map(element, mapOptions)

    // * Load and set marker in the map
    marker = new AdvancedMarkerElement({
        map,
        position: cords,
        title: 'Uluru',
    })

    // * Set actions buttons in Google Maps
    setActionButtons()
}

// * Update marker in Google Maps
function updateMarker(cords) {
    // * Center map on cords
    map.setCenter(cords)

    // * Update marker position
    marker.position = cords
}

// * Set action buttons in the Google Maps
function setActionButtons() {
    const buttons = [
        ["Rotate Left", "rotate", 20, google.maps.ControlPosition.LEFT_CENTER],
        ["Rotate Right", "rotate", -20, google.maps.ControlPosition.RIGHT_CENTER],
        ["Tilt Down", "tilt", 20, google.maps.ControlPosition.TOP_CENTER],
        ["Tilt Up", "tilt", -20, google.maps.ControlPosition.BOTTOM_CENTER],
    ]

    buttons.forEach(([text, mode, amount, position]) => {
        const controlDiv = document.createElement("div");
        const controlUI = document.createElement("button");

        controlUI.classList.add("ui-button");
        controlUI.innerText = `${text}`;
        controlUI.addEventListener("click", () => {
            adjustMap(mode, amount);
        })
        controlDiv.appendChild(controlUI);
        map.controls[position].push(controlDiv);
    })

    const adjustMap = function (mode, amount) {
        switch (mode) {
            case "tilt":
                map.setTilt(map.getTilt() + amount);
                break;
            case "rotate":
                map.setHeading(map.getHeading() + amount);
                break;
            default:
                break;
        }
    }
}
</script>